import "./personal-detail.scss";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import question from "../../../assets/img/question.svg";
import Button from "../../../component/Button/Button";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import { Formik, ErrorMessage, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import { userAxios } from "../../../utils/config";
import partnerLogo from "../../../assets/img/partner.svg";
import { PersonalDetailSchema } from "../../../helper/Validation";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

const PersonalDetail = ({}) => {
  const [data, setData] = useState();
  const [address, setAddress] = useState();
  const [years, setYears] = useState([]);
  const [number, setNumber] = useState(10);

  const [birthData, setBirthData] = useState({
    day: "",
    month: "",
    year: "",
  });
  const navigate = useNavigate();
  const maxOffset = 122;

  const userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");

  const getValues = async () => {
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      // console.log("response from account ==> ", response);
      const dateB = response?.data?.userInfo?.user_metadata?.birthdate?.substr(
        0,
        2
      );
      const dateM = response?.data?.userInfo?.user_metadata?.birthdate?.substr(
        3,
        2
      );
      const dateY = response?.data?.userInfo?.user_metadata?.birthdate?.substr(
        6,
        4
      );
      const birthDay = new Date(
        response?.data?.userInfo?.user_metadata?.birthdate
      ).getUTCDate();
      const birthMonth = new Date(
        response?.data?.userInfo?.user_metadata?.birthdate
      ).getUTCMonth();
      const birthYear = new Date(
        response?.data?.userInfo?.user_metadata?.birthdate
      ).getUTCFullYear();
      // console.log(
      //   "Year is also here =>",
      //   new Date(response?.user_metadata?.birthdate).getUTCDate(),
      //   new Date(response?.user_metadata?.birthdate).getUTCMonth(),
      //   new Date(response?.user_metadata?.birthdate).getUTCFullYear(),
      //   dateB,
      //   dateM,
      //   dateY
      // );
      setBirthData({
        ...birthData,
        date: dateB,
        month: dateM,
        year: dateY,
      });
      const date = setAddress(
        JSON.parse(response?.data?.userInfo?.user_metadata?.address)
      );
      // console.log(
      //   "address",
      //   JSON.parse(response?.data?.userInfo?.user_metadata?.address)
      // );
      setData(response?.data?.userInfo);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async (values, error) => {
    // console.log("error", error);
    if (number == "10") {
      const userValues = { ...values };
      // console.log("vALUES==>", values);
      const date = `${values.day}/${values.month}/${values.year}`;
      delete userValues.day;
      delete userValues.month;
      delete userValues.year;
      delete userValues.terms;
      userValues.dob = date;
      // console.log("data register-->", { ...userValues });

      // console.log(values);

      const address = JSON.stringify({
        street_address: userValues?.street_address,
        region: userValues?.region,
        postal_code: userValues?.postal_code,
        country: userValues?.country,
      });
      const userData = {
        user_metadata: {
          birthdate: userValues?.dob,
          phone_number: `+44 ${userValues?.phone_number}`,
          gender: userValues?.gender,
          address,
        },
      };

      const user_values = {
        given_name: userValues?.given_name,
        family_name: userValues?.family_name,
      };
      // console.log("userData", userData);
      // console.log("log nathi-padvo-->", user_values);
      try {
        const response = await userAxios.put(
          `edituser/${userId}`,
          {
            ...user_values,
            ...userData,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        // console.log("response from personal details ==> ", response);
        if (response) {
          setData(response);
          navigate("/account");
          toast.success("Profile updated successfully", {
            autoClose: 3000,
          });
          getValues();
        }
      } catch (error) {
        // console.log("error", error);
        toast.error("Something went wrong");
      }
      // const { user } = res?.data;

      // const address = JSON.stringify({
      //   street_address: user?.address?.streetAddress,

      //   locality: user?.address?.locality,

      //   region: user?.address?.region,

      //   postal_code: user?.address?.postalCode,

      //   country: user?.address?.country,
      // });

      // const userData = {
      //   user_metadata: {
      //     birthdate: user?.dob,

      //     phone_number: user?.contactNumber,

      //     gender: user?.gender,

      //     address,
      //   },
      // };

      // await auth0Manage?.patchUserAttributes(
      //   authUserId,
      //   userData,
      //   function (err, authResult) {
      //     if (err) {
      //       //   setLoading(false);
      //       console.log("error", err?.message);
      //       toast.error("Error is updating profile");
      //     } else {
      //       console.log(
      //         "patchUserMetadata succeeded: " + JSON.stringify(authResult)
      //       );
      //       //   userAuth.setUser(user);
      //       toast.success("Profile updated successfully");
      //       //   setActive(false);
      //     }
      //   }
      // );
    }
  };

  const handleError = (e) => {
    if (e["family_name"] || e["given_name"]) {
      document.getElementById("one").scrollIntoView();
    } else if (e["phone_number"]) {
      document.getElementById("two").scrollIntoView();
    } else if (e["country"] || e["region"] || e["street_address"]) {
      document.getElementById("three").scrollIntoView();
    }
  };

  useEffect(() => {
    if (userId) {
      getValues();
    } else {
      navigate("/");
    }
    var thisYear = new Date().getFullYear();
    let abc = [...years];
    for (var i = 0; i <= maxOffset; i++) {
      var year = thisYear - i;
      abc.push(year);
    }
    setYears([...abc]);
  }, []);
  return (
    <div className="userWrapper">
      {/* {console.log("year is here ==>", birthData.date)} */}
      <Formik
        enableReinitialize={true}
        initialValues={{
          given_name: data?.given_name || "",
          family_name: data?.family_name || "",
          gender: data?.user_metadata?.gender || "",
          day: birthData.date || "",
          month: birthData.month || "",
          year: birthData.year || "",
          phone_number: data?.user_metadata?.phone_number.includes("+44")
            ? data?.user_metadata?.phone_number?.slice(3, 20)
            : data?.user_metadata?.phone_number,
          email: data?.email || "",
          street_address: address?.street_address || "",
          region: address?.region || "",
          country: address?.country || "",
          postal_code: address?.postal_code || "",
        }}
        onSubmit={handleSubmit}
        validationSchema={PersonalDetailSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          validateForm,
          errors,
          touched,
        }) => (
          <FormikForm>
            {/* {console.log("gender ==>", data?.user_metadata?.gender)} */}
            <div className="formContainer">
              <Link to="/account">
                <h3>
                  <img className="img-fluid me-2" src={FaAngleLeft} />
                  Personal details
                </h3>
              </Link>
              <hr className="margin-separator" />
              <Row>
                <Col xs={6} id="one" className="custom-gutter">
                  <Form.Group className="formMargin">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First name"
                      name="given_name"
                      value={values.given_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="given_name" />
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={6} className="custom-gutter">
                  <Form.Group className="formMargin">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last name"
                      name="family_name"
                      value={values.family_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="family_name" />
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={6} className="custom-gutter">
                  <Form.Group className="formMargin">
                    <Form.Label>Sex</Form.Label>
                    <Form.Check
                      type="radio"
                      className="form-control"
                      label="Male"
                      name="gender"
                      id="radio1"
                      value="male"
                      onChange={handleChange}
                      checked={values.gender == "male" ? true : false}
                      onBlur={handleBlur}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} className="custom-gutter">
                  <Form.Group className="formMargin ">
                    <Form.Label className="justify-content-end">
                      <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Your Sex</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      className="form-control"
                      label="Female"
                      name="gender"
                      id="radio2"
                      value="female"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values.gender == "female" ? true : false}
                    />
                  </Form.Group>
                </Col>

                <Col xs={12}>
                  <Form.Label>
                    <span>Date of birth</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Input your date of birth in DD/MM/YYYY format</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
                  </Form.Label>
                </Col>

                <Form.Group
                  className="formMargin"
                  controlId="formBasicPassword"
                >
                  <div className="row">
                    <div className="col-4 custom-gutter">
                      <Form.Label className="font-16">Day</Form.Label>
                      <Form.Select
                        type="text"
                        placeholder="DD"
                        name="day"
                        className="form-control"
                        value={values.day}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      >
                        <option value="">DD</option>
                        {[
                          "01",
                          "02",
                          "03",
                          "04",
                          "05",
                          "06",
                          "07",
                          "08",
                          "09",
                          "10",
                          "11",
                          "12",
                          "13",
                          "14",
                          "15",
                          "16",
                          "17",
                          "18",
                          "19",
                          "20",
                          "21",
                          "22",
                          "23",
                          "24",
                          "25",
                          "26",
                          "27",
                          "28",
                          " 29",
                          "30",
                          " 31",
                        ].map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="global-font-body text-danger">
                        <ErrorMessage name="day" />
                      </div>
                    </div>
                    <div className="col-4 custom-gutter">
                      <Form.Label className="font-16">Month</Form.Label>
                      <Form.Select
                        type="text"
                        placeholder="MM"
                        name="month"
                        className="form-control"
                        value={values.month}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      >
                        <option value="">MM</option>
                        {[
                          "01",
                          "02",
                          "03",
                          "04",
                          "05",
                          "06",
                          "07",
                          "08",
                          "09",
                          "10",
                          "11",
                          "12",
                        ].map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="global-font-body text-danger">
                        <ErrorMessage name="month" />
                      </div>
                    </div>
                    <div className="col-4 custom-gutter">
                      <Form.Label className="font-16">Year</Form.Label>
                      <Form.Select
                        type="text"
                        placeholder="YYYY"
                        className="form-control"
                        name="year"
                        value={values.year}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      >
                        <option value="">YYYY</option>
                        {years.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="global-font-body text-danger">
                        <ErrorMessage name="year" />
                      </div>
                    </div>
                  </div>
                </Form.Group>

                {/* <Col xs={6}>
                  <Form.Label className="justify-content-end">
                    <span>
                      <img src={question} />
                    </span>
                  </Form.Label>
                </Col>
                <Col xs={4}>
                  <Form.Group className="formMargin">
                    <Form.Label>Day</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Day"
                      name="day"
                      maxLength={"2"}
                      value={values.day}
                      onChange={handleChange}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group className="formMargin">
                    <Form.Label>Month</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Month"
                      name="month"
                      maxLength={"2"}
                      value={values.month}
                      onChange={handleChange}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group className="formMargin">
                    <Form.Label>Year</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Year"
                      name="year"
                      maxLength={"4"}
                      value={values.year}
                      onChange={handleChange}
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </Form.Group>
                </Col> */}
                <Col xs={12}>
                  <h3 className="text-md-center mb-24">Contact details</h3>
                </Col>
                <Col xs={12} id="two">
                  <Form.Group className="formMargin">
                    <Form.Label>
                      <span>Phone</span>
                      <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Your Phone Number</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
                    </Form.Label>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <Form.Control
                        type="text"
                        placeholder=""
                        name="phone_number"
                        value={values.phone_number}
                        style={{
                          paddingLeft: "50px",
                        }}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          // if (e.target.value == "") {
                          //   setFieldValue("phone_number", "+44");
                          // } else if (e.target.value.length == 2) {
                          //   setFieldValue("phone_number", "+44");
                          // } else {
                          // console.log(
                          //   ' e.target.value?.replaceAll(" ", "")',
                          //   e.target.value?.replaceAll(" ", "").length
                          // );
                          setFieldValue(
                            "phone_number",
                            e.target.value?.replaceAll(" ", "")
                          );
                          setNumber(e.target.value?.replaceAll(" ", "").length);
                          // }
                        }}
                        // onKeyPress={(e) =>
                        //   !/[0-9]/.test(e.key) && e.preventDefault()
                        // }
                      />
                      {/* {!values.phone_number.includes("+44") ? ( */}
                      <div
                        style={{
                          position: "absolute",
                          top: "11px",
                          left: "11px",
                          fontSize: "18px",
                        }}
                      >
                        +44
                      </div>
                      {/* ) : (
                        <></>
                      )} */}
                    </div>
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="phone_number" />
                    </div>
                    {number != "10" ? (
                      <div className="global-font-body text-danger">
                        Phone number must be equal to 12
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {console.log("values---> number", values, number)} */}
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="formMargin">
                    <Form.Label>
                      <span>Email</span>
                      <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Your email address</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="email" />
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group className="formMargin">
                    <Form.Label>
                      <span>Address</span>
                      <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Your Address</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="street_address"
                      value={values.street_address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="street_address" />
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12} id="three">
                  <Form.Group className="formMargin">
                    <Form.Control
                      type="text"
                      placeholder="Address line 1"
                      name="region"
                      value={values.region}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="region" />
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={8} className="custom-gutter">
                  <Form.Group className="formMargin">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="country" />
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={4} className="custom-gutter">
                  <Form.Group className="formMargin">
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="postal_code"
                      value={values.postal_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <div className="global-font-body text-danger">
                      <ErrorMessage name="postal_code" />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <Button
              name="Save"
              type="submit"
              onClick={() => validateForm().then((e) => handleError(e))}
            />
          </FormikForm>
        )}
      </Formik>
      <div className="partnerShip">
        <p>In partnership with</p>
        <img className="img-fluid" src={partnerLogo} />
      </div>
    </div>
  );
};

export default PersonalDetail;
