import { decryptResponse, encryptResponse } from "../cryptoutils";
import { baseUrl } from "./baseUrl";
import CryptoJS from "crypto-js";

import axios from "axios";
// authUser
export const userAxios = axios.create({
  baseURL: baseUrl,
});

// Add a response interceptor
userAxios.interceptors.response.use(
  (response) => {
    const accessToken = sessionStorage.getItem("accessToken");
    // Fetch the X-Access-Token from the response headers
    //  const xAccessToken = response.headers['x-access-token'];
    //  console.log(xAccessToken);
    // Encrypt the access token using AES encryption
    // if (accessToken) {
    //   const keyencryption = "Hello"
    //   const encryptedAccessToken = CryptoJS.AES.encrypt(accessToken, keyencryption).toString();
    //   console.log(encryptedAccessToken);
    // }
    if (accessToken) {
      const decryptedResponse = decryptResponse(response.data, accessToken);
      response.data = decryptedResponse;
      return response;
    } else {
      const decryptedResponse = decryptResponse(response.data, "SmartInsurance");
      response.data = decryptedResponse;
      return response;
    }
  },
  (error) => {
    if (error.response && error.response.data) {
      const accessToken = sessionStorage.getItem("accessToken");
      if (accessToken) {
        const decryptedError = decryptResponse(
          error.response.data,
          accessToken
        );
        console.log("........decrypted error.........", decryptedError)
        error.response.data = decryptedError;
      } else {
        const decryptedError = decryptResponse(
          error.response.data,
          "SmartInsurance"
          );
          console.log("........decrypted error.........", decryptedError)
        error.response.data = decryptedError;
      }
    }
    return Promise.reject(error);
  }
);

// Add a request interceptor
userAxios.interceptors.request.use(
  (config) => {
    // console.log(config.data,"......interceptors....");
    const accessToken = sessionStorage.getItem("accessToken");
    // console.log(accessToken);
    // Encrypt the access token using AES encryption
    // if (accessToken) {
    //   const keyencryption = "Hello"
    //   const encryptedAccessToken = CryptoJS.AES.encrypt(accessToken, keyencryption).toString();
    // }
    config.headers["X-Access-Token"] = accessToken;
    if (accessToken) {
      const encryptedPayload = encryptResponse(
        JSON.stringify(config.data),
        accessToken
      );
      const encodedPayload = encodeURIComponent(encryptedPayload);
      config.data = `encryptedPayload=${encodedPayload}`;
      return config;
    } else {
      // console.log("hi...");
      const encryptedPayload = encryptResponse(
        JSON.stringify(config.data),
        "SmartInsurance"
      );
      const encodedPayload = encodeURIComponent(encryptedPayload);
      config.data = `encryptedPayload=${encodedPayload}`;
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// export const otpAxios = axios.create({
//   baseURL: otpBase,
// });

// export const imageAxios = axios.create({
//   baseURL: imageBase,
// });
