import "./Register.scss";
import Form from "react-bootstrap/Form";
import Button from "../../component/Button/Button";
import BorderButton from "../../component/BorderButton/BorderButton";
import partnerLogo from "../../assets/img/partner.svg";
import question from "../../assets/img/question.svg";
import { useEffect, useState } from "react";
import { DataStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { auth } from "../../services/auth0.service";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AUTH0_CODE_CHALLENGE_METHOD,
  AUTH0_LOGIN_RESPONSE_TYPE,
  AUTH0_REALM,
} from "../../config";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../component/Loader/Loader";
import { userAxios } from "../../utils/config";
import { FaCheckCircle } from "react-icons/fa";
import { Col } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { encryptResponse } from "../../utils/cryptoutils";
var CryptoJS = require("crypto-js");

const CreatePassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState();
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [capital, setCapital] = useState(false);
  const [number, setNumber] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [length, setLength] = useState(false);
  const [type, setType] = useState("password");
  const [type2, setType2] = useState("password");
  const navigate = useNavigate();

  const { dataState, dataDispatch, versionState } = DataStore();
  // console.log("data state =>", dataState);
  // console.log("version  state =>", versionState.data);

  const checkPassword = (str) => {
    let errors = [];
    if (str.length >= 12) {
      setLength(true);
      // console.log("greater than 12");
      errors.push("Your password must be at least 8 characters");
    } else {
      setLength(false);
    }
    if (str.search(/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/) >= 0) {
      setSymbol(true);
      // console.log("symbol ");
      errors.push("Your password must contain at least one letter.");
    } else {
      setSymbol(false);
    }

    if (str.search(/[0-9]/) >= 0) {
      // console.log("STRRR search", str.search(/[0-9]/));
      setNumber(true);
      // console.log("number ");
      errors.push("Your password must contain at least one digit.");
    } else {
      setNumber(false);
    }
    // if (errors.length > 0) {
    //   return false;
    // }
    // return true;
    if (str.search(/[A-Z]/) >= 0) {
      setCapital(true);
      // console.log("capital letter");
      errors.push("Your password must contain at least one letter.");
    } else {
      setCapital(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (formData.password === formData.confirmPassword) {
      if (formData) {
        const address = JSON.stringify({
          street_address: dataState?.data?.address?.streetAddress,
          locality: dataState?.data?.address?.locality,
          region: dataState?.data?.address?.region,
          postal_code: dataState?.data?.address?.postalCode,
          country: dataState?.data?.address?.country,
        });

        const userData = {
          email: dataState?.data?.email,
          // email: "Kushal@gmail.com",
          given_name: dataState?.data?.firstName,
          family_name: dataState?.data?.surname,
          user_metadata: {
            phone_number: dataState?.data?.contactNumber,
            birthdate: dataState?.data?.dob,
            gender: dataState?.data?.gender,
            address: address ? address : {},
          },
        };
        try {
          //Account created Email (mailjet)
          const response = await userAxios.post(
            `/sendNewEmail/${dataState?.data?.email}/4905753`,
            {}
          );
        } catch (error) {}
        try {
          //Welcome Email (mailjet)
          const twoResponse = await userAxios.post(
            `/sendNewEmail/${dataState?.data?.email}/4905810`,
            {}
          );
        } catch (error) {}
        try {
          const response = await userAxios.post("/register", {
            insuranceNumber: dataState?.data?.insuranceNumber,
            email: dataState?.data?.email,
            version: versionState?.data,
          });
          // console.log("response--->", response);
        } catch (error) {
          console.log("errror", error);
        }
        auth.signup(
          {
            ...userData,
            password: formData?.password,
            connection: AUTH0_REALM,
          },
          (error, result) => {
            if (error) {
              // console.log("Err signup api", error);
              toast.error("Password is too weak");
              return;
            } else {
              dataDispatch({ type: 1, payload: "" });
              toast.success("User registered successfully");
              // navigate("/");
              // console.log("result of signup ==>", result);
              if (result) {
                function base64URLEncode(str) {
                  return str
                    .replace(/\+/g, "-")
                    .replace(/\//g, "_")
                    .replace(/=/g, "");
                }
                function dec2hex(dec) {
                  return dec.toString(16).padStart(2, "0");
                }
                function generateId(len) {
                  var arr = new Uint8Array((len || 40) / 2);
                  window.crypto.getRandomValues(arr);
                  return Array.from(arr, dec2hex).join("");
                }
                var verifier = generateId(50);

                let basecodeChallenge = CryptoJS.SHA256(verifier).toString(
                  CryptoJS.enc.Base64
                );
                basecodeChallenge = base64URLEncode(basecodeChallenge);
                sessionStorage.setItem("verifier", verifier);
                // console.log(
                //   "Env redirect",
                //   process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL
                // );
                auth.login(
                  {
                    username: result?.email,
                    password: formData?.password,
                    redirectUri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL,
                    realm: AUTH0_REALM,
                    responseType: AUTH0_LOGIN_RESPONSE_TYPE,
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    code_challenge: basecodeChallenge,
                    code_challenge_method: AUTH0_CODE_CHALLENGE_METHOD,
                  },

                  (error, result) => {
                    if (error) {
                      toast.error(
                        "Login failed; Invalid user ID or password.",
                        {
                          autoClose: 3000,
                        }
                      );

                      // setErrorMessage({
                      //   message: error?.error,

                      //   description: error?.error_description,
                      // });
                      setLoading(false);
                      return;
                    }

                    toast.success("User logged in successfully", {
                      autoClose: 3000,
                    });
                    setLoading(false);
                    // setErrorMessage({
                    //   message: "",

                    //   description: "",
                    // });
                    // naviagte("/dashboard");
                  }
                );
              }
            }
          }
        );
        // http://localhost:3264/api/v1/user/sendNewEmail/:email/:tmpid
      }
    } else {
      setPasswordMatch(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!dataState?.data?.firstName) {
      navigate("/register");
    }
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="userWrapper">
          <div className="threeStep">
            <h2>Just a few last steps to get you onboard!</h2>
            <p>Please set a password for access to your account.</p>
            <Form className="formContainer">
              <Form.Group className="formMargin" controlId="formBasicPassword">
                <Form.Label>
                  <span>Password</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Enter memorable password.</Tooltip>}
                  >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <div className="password-main-div">
                  <Form.Control
                    type={type}
                    placeholder="Password"
                    value={formData.password}
                    onChange={(e) => {
                      setFormData({ ...formData, password: e.target.value });
                      checkPassword(e.target.value);
                    }}
                  />
                  <div
                    className="eye-icon"
                    onClick={() => {
                      if (type == "password") {
                        setType("text");
                      } else setType("password");
                    }}
                  >
                    {type != "password" ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </div>
                </div>
              </Form.Group>

              <Form.Group
                className="formMargin"
                controlId="formBasicConfirmPassword"
              >
                <Form.Label>
                  <span>Confirm Password</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Confirm password</Tooltip>}
                  >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <div className="password-main-div">
                  <Form.Control
                    type={type2}
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        confirmPassword: e.target.value,
                      });
                      if (e.target.value !== formData.password)
                        setPasswordMatch(false);
                      else setPasswordMatch(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                  />
                  <div
                    className="eye-icon"
                    onClick={() => {
                      if (type2 == "password") {
                        setType2("text");
                      } else setType2("password");
                    }}
                  >
                    {type2 != "password" ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </div>
                </div>
                {!passwordMatch && (
                  <div className="text-danger mt-2">
                    Passwords are not matching
                  </div>
                )}
              </Form.Group>
              <Col xs={12}>
                <div className="password-condtion">
                  <p>Passwords must include at least:</p>
                  <p className={length ? "active" : ""}>
                    <FaCheckCircle />
                    12 characters
                  </p>
                  <p className={capital ? "active" : ""}>
                    <FaCheckCircle />1 capital letter
                  </p>
                  <p className={number ? "active" : ""}>
                    <FaCheckCircle />1 number
                  </p>
                  <p className={symbol ? "active" : ""}>
                    <FaCheckCircle />1 special symbol
                  </p>
                </div>
              </Col>
            </Form>
            <Button
              disabled={
                !passwordMatch ||
                !length ||
                !capital ||
                !number ||
                !symbol ||
                !formData.confirmPassword
              }
              name="Create account"
              type="submit"
              onClick={handleSubmit}
            />
            <div className="partnerShip">
              <p>In partnership with</p>
              <img src={partnerLogo} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePassword;
