import "./ForgotPassword.scss";
import Form from "react-bootstrap/Form";
import Button from "../../component/Button/Button";
import BorderButton from "../../component/BorderButton/BorderButton";
import partnerLogo from "../../assets/img/partner.svg";
import question from "../../assets/img/question.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { userAxios } from "../../utils/config";
import { FaCheckCircle } from "react-icons/fa";
import { Col } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
const ForgotPassword = ({}) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [code, setCode] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [capital, setCapital] = useState(false);
  const [number, setNumber] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [length, setLength] = useState(false);
  const [type, setType] = useState("password");
  const [type2, setType2] = useState("password");
  const [token, setToken] = useState();
  const navigate = useNavigate();

  const userToken = sessionStorage.getItem("accessToken");

  const firstInputRef = useRef(null);

  const checkPassword = (str) => {
    let errors = [];
    if (str.length >= 12) {
      setLength(true);
      // console.log("greater than 12");
      errors.push("Your password must be at least 8 characters");
    } else {
      setLength(false);
    }
    if (str.search(/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/) >= 0) {
      setSymbol(true);
      // console.log("symbol ");
      errors.push("Your password must contain at least one letter.");
    } else {
      setSymbol(false);
    }

    if (str.search(/[0-9]/) >= 0) {
      // console.log("STRRR search", str.search(/[0-9]/));
      setNumber(true);
      // console.log("number ");
      errors.push("Your password must contain at least one digit.");
    } else {
      setNumber(false);
    }
    // if (errors.length > 0) {
    //   return false;
    // }
    // return true;
    if (str.search(/[A-Z]/) >= 0) {
      setCapital(true);
      // console.log("capital letter");
      errors.push("Your password must contain at least one letter.");
    } else {
      setCapital(false);
    }
  };
  let digitValidate = function (ele) {
    // console.log(ele.value);
    ele.value = ele.value.replace(/[^0-9]/g, "");
  };

  let tabChange = function (val) {
    let ele = document.querySelectorAll("input");
    if (ele[val - 1].value != "") {
      if (ele[val]) {
        ele[val].focus();
      }
    } else if (ele[val - 1].value == "" && ele[val - 2]) {
      ele[val - 2].focus();
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (email) {
      // console.log("email is here =>", email);
      try {
        const response = await userAxios.get(`/forgotpassword/${email}`);
        // console.log("Response of forgot pass==>", response);
        if (response.data.data.length == 0) {
          toast.error("Please enter valid email");
        } else {
          setUserId(response?.data?.data[0]?.identities[0]?.user_id);
          setStep(2);
        }
      } catch (error) {
        // console.log("error in forgot pass=>", error);
        toast.error(error.response.data.message, { autoClose: 2000 });
      }
    } else {
      toast.error("Please enter email");
    }
  };

  const handleCode = async () => {
    // console.log("values==>", { ...code });
    const { first, second, third, fourth, fifth, sixth } = code;

    if (first && second && third && fourth && fifth && sixth) {
      const otp = first + second + third + fourth + fifth + sixth;
      // console.log("aaa==>", otp);
      try {
        const response = await userAxios.post("/forgototpverify", {
          otp,
          email,
          id: userId,
        });
        if (response) {
          setToken(response.data.token);
          // toast.success("Verified successfully", { autoClose: 3000 });
          // navigate("/register/create-password");
          setStep(3);
        }
        // console.log("response verify forgot ==>", response);
      } catch (error) {
        console.log("error", error);
        // console.log("Elseee");
        toast.error(error.response.data.message, { autoClose: 3000 });
      }
    } else {
      // console.log("Elseee");
      toast.error("Please enter correct code", { autoClose: 3000 });
    }
  };

  const handleSubmit = async () => {
    // console.log("id is here =>", userId);
    if (formData.password === formData.confirmPassword) {
      if (capital && number && length && symbol) {
        try {
          const response = await userAxios.put(
            `forgotPasswordchange/auth0|${userId}`,
            {
              password: formData?.password,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log("Respponse ==>", response);
          if (response) {
            try {
              const emailResponse = await userAxios.post(
                `/sendNewEmail/${email}/4348651`,
                {}
              );
            } catch (error) {}
            navigate("/");
            toast.success("Profile updated successfully", {
              autoClose: 3000,
            });
          }
        } catch (error) {
          console.log("error", error);
          console.log("error message", error.status);
          toast.error("Something went wrong");
        }
      } else {
        toast.error("Please fulfill all criteria of password");
      }
    } else {
      toast.error("Password does not match");
      setPasswordMatch(false);
    }
  };

  useEffect(() => {
    if (userToken) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus(); // Set focus on the first input element
    }
  }, []);
  return (
    <div className="userWrapper">
      {step && step === 1 ? (
        <div className="firstStep forgotPasswordp">
          <h2>Forgot Password</h2>
          <p>
            Enter your email for the verfification process, we will send a 6
            digit code on your email.
          </p>
          <Form className="formContainer">
            <Form.Group className="formMargin">
              <Form.Label>
                <span>Email</span>
                <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Enter your email address.</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {if (e.key === "Enter") {
                  sendEmail(e)
                }}}
              />
            </Form.Group>

            <Button
              name="Continue"
              type="submit"
              onClick={(e) => sendEmail(e)}
            />
          </Form>
          {/* <Link to="/" className="NoAccount"> */}
          <div className="NoAccount">Don't have a account</div>
          {/* </Link> */}
          <BorderButton
            name="Create an account"
            type="button"
            onClick={() => navigate("/register")}
          />
          <div className="partnerShip">
            <p>In partnership with</p>
            <img src={partnerLogo} />
          </div>
        </div>
      ) : step === 2 ? (
        <div className="twoStep forgotPasswordp">
          <h2>Confirm your identity</h2>
          <p>
            Please enter the 6 digit below that we have sent to your email
            address.
          </p>
          <Form className="formContainer">
            <div className="form-label label">
              <span>Verification code</span>
              <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Enter 6 digit code.</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
            </div>
            <Form.Group className="formMargin" controlId="formBasicPassword">
              <div className="row">
                <div className="col-2">
                  <Form.Control
                    type="text"
                    value={code.first}
                    maxLength={1}
                    onChange={(e) =>
                      setCode({ ...code, first: e.target.value })
                    }
                    onKeyUp={(e) => tabChange(1)}
                    onInput={(e) => digitValidate(e.target)}
                  />
                </div>
                <div className="col-2">
                  <Form.Control
                    type="text"
                    maxLength={1}
                    value={code.second}
                    onChange={(e) =>
                      setCode({ ...code, second: e.target.value })
                    }
                    onKeyUp={(e) => tabChange(2)}
                    onInput={(e) => digitValidate(e.target)}
                    ref={firstInputRef}
                  />
                </div>
                <div className="col-2">
                  <Form.Control
                    type="text"
                    maxLength={1}
                    value={code.third}
                    onChange={(e) =>
                      setCode({ ...code, third: e.target.value })
                    }
                    onKeyUp={(e) => tabChange(3)}
                    onInput={(e) => digitValidate(e.target)}
                  />
                </div>
                <div className="col-2">
                  <Form.Control
                    type="text"
                    maxLength={1}
                    value={code.fourth}
                    onChange={(e) =>
                      setCode({ ...code, fourth: e.target.value })
                    }
                    onKeyUp={(e) => tabChange(4)}
                    onInput={(e) => digitValidate(e.target)}
                  />
                </div>
                <div className="col-2">
                  <Form.Control
                    type="text"
                    maxLength={1}
                    value={code.fifth}
                    onChange={(e) =>
                      setCode({ ...code, fifth: e.target.value })
                    }
                    onKeyUp={(e) => tabChange(5)}
                    onInput={(e) => digitValidate(e.target)}
                  />
                </div>
                <div className="col-2">
                  <Form.Control
                    type="text"
                    maxLength={1}
                    value={code.sixth}
                    onChange={(e) =>
                      setCode({ ...code, sixth: e.target.value })
                    }
                    onKeyUp={(e) => tabChange(6)}
                    onInput={(e) => digitValidate(e.target)}
                    onKeyDown={(e) => {if (e.key === "Enter") {
                      e.preventDefault();
                      handleCode()
                    }}}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
          <Button name="Confirm" type="button" onClick={() => handleCode()} />
          <div className="partnerShip">
            <p>In partnership with</p>
            <img src={partnerLogo} />
          </div>
        </div>
      ) : step === 3 ? (
        <div className="threeStep forgotPasswordp">
          <h2>Reset Password</h2>
          <p>
            Set the new password for your account so you can login and access
            all the features
          </p>
          <Form className="formContainer">
            <Form.Group className="formMargin" controlId="formBasicPassword">
              <Form.Label>
                <span>New Password</span>
                <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Enter memorable password.</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
              </Form.Label>
              <div className="password-main-div">
                <Form.Control
                  type={type}
                  placeholder="Password"
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    checkPassword(e.target.value);
                  }}
                />
                <div
                  className="eye-icon"
                  onClick={() => {
                    if (type == "password") {
                      setType("text");
                    } else setType("password");
                  }}
                >
                  {type != "password" ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}
                </div>
              </div>
            </Form.Group>

            <Form.Group
              className="formMargin"
              controlId="formBasicConfirmPassword"
            >
              <Form.Label>
                <span>Re-Enter Password</span>
                <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Confirm new password</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
              </Form.Label>
              <div className="password-main-div">
                <Form.Control
                  type={type2}
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      confirmPassword: e.target.value,
                    });
                    if (e.target.value !== formData.password)
                      setPasswordMatch(false);
                    else setPasswordMatch(true);
                  }}
                  onKeyDown={(e) => {if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit()
                  }}}
                />
                <div
                  className="eye-icon"
                  onClick={() => {
                    if (type2 == "password") {
                      setType2("text");
                    } else setType2("password");
                  }}
                >
                  {type2 != "password" ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}
                </div>
              </div>
              {!passwordMatch && (
                <div className="text-danger mt-2">
                  Passwords are not matching
                </div>
              )}
            </Form.Group>
            <Col xs={12}>
              <div className="password-condtion">
                <p>Passwords must include at least:</p>
                <p className={length ? "active" : ""}>
                  <FaCheckCircle />
                  12 characters
                </p>
                <p className={capital ? "active" : ""}>
                  <FaCheckCircle />1 capital letter
                </p>
                <p className={number ? "active" : ""}>
                  <FaCheckCircle />1 number
                </p>
                <p className={symbol ? "active" : ""}>
                  <FaCheckCircle />1 special symbol
                </p>
              </div>
            </Col>
          </Form>
          <Button
            name="Continue"
            onClick={handleSubmit}
            disabled={!passwordMatch}
            type="button"
          />
          <div className="partnerShip">
            <p>In partnership with</p>
            <img src={partnerLogo} />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ForgotPassword;
