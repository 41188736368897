import React, { useEffect, useState } from "react";
import "./RequestList.scss";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import { nextData, prevData } from "../../../Redux/Reducers/NurseChampion";
import { userAxios } from "../../../utils/config";
import { FaAngleLeft as FaLeft } from "react-icons/fa";
import partnerLogo from "../../../assets/img/partner.svg";

const Singlerequest = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  let userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const selectorData = useSelector((store) => store.nurseChampion);
  const data = selectorData.requestEntries[selectorData.requestID];
  const navigate = useNavigate();
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setUserData(response?.data?.userInfo);
      setLoading(false);
    } catch (error) {
      console.log("eeror in dashboard", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("selectorData", selectorData);
    if (
      selectorData?.requestEntries.length == 0 ||
      selectorData?.requestID === ""
    )
      navigate("/request-list");
    else getData();
  }, []);

  return (
    <>
      {selectorData?.requestEntries.length == 0 ||
      selectorData?.requestID === "" ? (
        <></>
      ) : (
        <div className="commonWrapper">
          <Link to={"/request-list"}>
            <h3>
              <img className="img-fluid me-2" src={FaAngleLeft} />
              <div className="heading">
                <span>{userData?.given_name ?? "User"}'s request</span>
                <span>{data?.newDate}</span>
                {/* <span>Sept, 17 2022</span> */}
              </div>
            </h3>
          </Link>
          <hr />
          <div className="mood-selction-info">
            <div className="selected-option-info">
              <label>Personal Details</label>
              <p className="mb-0">
                {data?.details?.firstName} {data?.details?.surname}
              </p>
              <p className="mb-0">{data?.details?.contactNumber}</p>
              <p className="mb-0">
                {data?.details?.address?.streetAddress &&
                  `${data.details.address.streetAddress}, `}
                {data?.details?.address?.locality &&
                  `${data.details.address.locality}, `}
                {data?.details?.address?.region &&
                  `${data.details.address.region}, `}
                {data?.details?.address?.country &&
                  `${data.details.address.country}`}
              </p>

              {data?.details?.address?.postalCode && (
                <p className="mb-0">{data?.details?.address?.postalCode}</p>
              )}
            </div>
            <div className="selected-option-info">
              <label>Email</label>
              <p className="mb-0">{data?.email}</p>
            </div>
            <div className="selected-option-info">
              <label>Your availability</label>
              {data?.date?.map((item, index) => (
                <p key={index} className="mb-0">
                  {item}
                </p>
              ))}
            </div>
            <div className="sliding-btns d-flex align-items-center mt-40">
              <div className="col-10">
                {selectorData?.requestID != 0 && (
                  <Link>
                    <img className="faLeftclass" src={FaAngleLeft} />
                    {/* <FaLeft /> */}
                    <span
                      onClick={() => {
                        if (selectorData?.requestID != 0)
                          dispatch(prevData(selectorData.requestID - 1));
                        window.scrollTo(0, 0);
                      }}
                    >
                      Previous
                    </span>
                  </Link>
                )}
              </div>
              <div className="col-2">
                {selectorData?.requestID !=
                  selectorData.requestEntries.length - 1 && (
                  <Link>
                    <span
                      onClick={() => {
                        if (
                          selectorData?.requestID !=
                          selectorData.requestEntries.length - 1
                        )
                          dispatch(nextData(selectorData.requestID + 1));
                        window.scrollTo(0, 0);
                      }}
                    >
                      Next
                    </span>
                    <img src={FaAngleLeft} />
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="text-center partnerWith">
            <p>In partnership with</p>
            <img className="img-fluid" src={partnerLogo} />
          </div>
        </div>
      )}
    </>
  );
};

export default Singlerequest;
