import "./login.scss";
import Form from "react-bootstrap/Form";
import Button from "../../component/Button/Button";
import BorderButton from "../../component/BorderButton/BorderButton";
import partnerLogo from "../../assets/img/partner.svg";
import question from "../../assets/img/question.svg";
import { Link, useNavigate } from "react-router-dom";
import { Formik, useFormik, Form as FormikForm, ErrorMessage } from "formik";
import { SignInSchema } from "../../helper/Validation";
import { toast } from "react-toastify";
import { userAxios } from "../../utils/config";
import { auth } from "../../services/auth0.service";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  AUTH0_CODE_CHALLENGE_METHOD,
  AUTH0_LOGIN_RESPONSE_TYPE,
  AUTH0_REALM,
} from "../../config";
import { useEffect, useState } from "react";
import Loader from "../../component/Loader/Loader";
import { set } from "date-fns";
import axios from "axios";
var CryptoJS = require("crypto-js");

const Login = ({}) => {
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  // console.log("Env cred==>", process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL);
  const [type, setType] = useState("password");
  const userId = sessionStorage.getItem("authUserId");

  const HandleSubmit = async (values) => {
    // console.log("vALUES==>", values);
    setLoading(true);
    if (values) {
      function base64URLEncode(str) {
        return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
      }
      function dec2hex(dec) {
        return dec.toString(16).padStart(2, "0");
      }
      function generateId(len) {
        var arr = new Uint8Array((len || 40) / 2);
        window.crypto.getRandomValues(arr);
        return Array.from(arr, dec2hex).join("");
      }
      var verifier = generateId(50);

      let basecodeChallenge = CryptoJS.SHA256(verifier).toString(
        CryptoJS.enc.Base64
      );
      basecodeChallenge = base64URLEncode(basecodeChallenge);
      sessionStorage.setItem("verifier", verifier);
      // console.log(
      //   "Env redirect",
      //   process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL
      // );
      userAxios
        .post("isblocked", { email: values?.email })
        .then((response) => {
          if (response.status === 200) {
            auth.login(
              {
                username: values?.email,
                password: values?.password,
                redirectUri: process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URL,
                realm: AUTH0_REALM,
                responseType: AUTH0_LOGIN_RESPONSE_TYPE,
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                code_challenge: basecodeChallenge,
                code_challenge_method: AUTH0_CODE_CHALLENGE_METHOD,
              },

              (error, result) => {
                if (error) {
                  // console.log(error);
                  setLoading(false);

                  userAxios
                    .post(`login-attempts`, {
                      email: values?.email,
                    })
                    .then(function (response) {
                      console.log(response);
                      toast.error(response.data.message, {
                        autoClose: 3000,
                      });
                      // toast.error(error?.description || error?.error_description || "An error occurred during login", {
                      //   autoClose: 3000,
                      // });
                    })
                    .catch((error) => {
                      console.log(error);
                    });

                  setLoading(false);
                  return;
                }

                toast.success("User logged in successfully", {
                  autoClose: 3000,
                });
                setLoading(false);
              }
            );
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, { autoClose: 3000 });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (userId) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="userWrapper">
          <h2>Health and wellbeing services</h2>
          <p>Please login below to access your account.</p>

          <Formik
            initialValues={{
              password: "",
              email: "",
            }}
            onSubmit={HandleSubmit}
            validationSchema={SignInSchema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form
                className="formContainer"
                onSubmit={handleSubmit}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <Form.Group className="formMargin" controlId="formBasicEmail">
                  <Form.Label>
                    <span>Email</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>Enter your email address to log in.</Tooltip>
                      }
                    >
                      <span>
                        <img src={question} alt="question" />
                      </span>
                    </OverlayTrigger>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    autoComplete="email" // Add this attribute
                  />
                  <div className="global-font-body text-danger">
                    <ErrorMessage name="email" />
                  </div>
                </Form.Group>

                <Form.Group
                  className="formMargin"
                  controlId="formBasicPassword"
                >
                  <Form.Label>
                    <span>Password</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>
                          Type your account password to access your services.
                        </Tooltip>
                      }
                    >
                      <span>
                        <img src={question} alt="question" />
                      </span>
                    </OverlayTrigger>
                  </Form.Label>
                  <div className="password-main-div">
                    <Form.Control
                      type={type}
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      autoComplete="current-password" // Add this attribute
                    />
                    <div
                      className="eye-icon"
                      onClick={() => {
                        if (type === "password") {
                          setType("text");
                        } else setType("password");
                      }}
                    >
                      {type !== "password" ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </div>
                  </div>
                  <div className="global-font-body text-danger">
                    <ErrorMessage name="password" />
                  </div>
                </Form.Group>

                <div className="forgotPassword">
                  <Link to="/forgot-password">Forgot Password ?</Link>
                </div>
                <Button name="Sign in" type="submit" />
              </Form>
            )}
          </Formik>

          <div className="NoAccount">Don’t have an account?</div>
          <BorderButton
            name="Create an account"
            onClick={() => navigate("/register")}
          />

          <div className="partnerShip">
            <p>In partnership with</p>
            <img src={partnerLogo} alt="partner logo" />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
