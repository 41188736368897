import React, { useEffect, useState } from "react";
import "./ConfirmChampion.scss";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import BorderButton from "../../../component/BorderButton/BorderButton";
import Button from "../../../component/Button/Button";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setNurseMessage } from "../../../Redux/Reducers/NurseChampion";
import { userAxios } from "../../../utils/config";
import Loader from "../../../component/Loader/Loader";
import partnerLogo from "../../../assets/img/partner.svg";

import { toast } from "react-toastify";
import { axiosInstance } from "../../../utils/config/axiosInstance";

const ConfirmChampion = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  let userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const [edit, setEdit] = useState({ address: false, email: false });
  const [formData, setFormData] = useState({
    address: "1 Wollaton Street, Nottingham NG15FS",
    streetAddress: "",
    locality: "",
    region: "",
    country: "",
    postalCode: "",
    contactNumber: "",
    email: "",
    date: [],
    tnc: false,
    consent: false,
  });
  const date = new Date();
  const arr = [],
    arr1 = [];
  const newDate = date.toLocaleString("en-US", {
    weekday: "short",
    day: "numeric",
    month: "short",
  });
  arr.push(date);
  arr1.push(newDate);
  for (let i = 1; i < 7; i++) {
    const nextDay = new Date(arr[i - 1]);
    nextDay.setDate(arr[i - 1].getDate() + 1);
    const nextDate = nextDay.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
    });
    arr.push(nextDay);
    arr1.push(nextDate);
  }

  const handleSubmit = async () => {
    // console.log("formdata ==>", formData, userData);
    let fromMail = formData.email;
    let localeDate = formData.date;
    // let localeDate = formData.date.toString();
    const obj = { ...formData };
    const addressObj = {
      streetAddress: formData.streetAddress,
      locality: formData.locality,
      region: formData.region,
      country: formData.country,
      postalCode: formData.postalCode,
    };
    const customerData = {
      name: userData?.given_name,
      dob: userData?.user_metadata?.birthdate,
      phonenumber: userData?.user_metadata?.phone_number,
      availability: localeDate,
      termsAccepted: "Yes",
      privacyAccepted: "Yes",
    };
    delete obj.address;
    delete obj.tnc;
    delete obj.consent;
    delete obj.postalCode;
    delete obj.region;
    delete obj.country;
    delete obj.locality;
    delete obj.streetAddress;
    delete obj.contactNumber;
    if (userId.startsWith("auth0|")) {
      userId = userId.slice(6);
    }
    obj.userId = userId;
    obj.createdAt = new Date().toISOString();
    obj.details = {
      contactNumber: userData?.user_metadata?.phone_number,
      address: { ...addressObj },
    };

    // console.log("Obj from create request==>", obj);
    // console.log("Obj from  customer data==>", customerData);
    try {
      const token = sessionStorage.getItem("accessToken");

      if (token)
        axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + token;

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/nursechampion`,
        {
          ...obj,
        }
      );
      // console.log("Mood journal response", response);
      // console.log(response.status);
      if (response.status == 200) {
        dispatch(setNurseMessage({ nurseMessage: true, date: new Date() }));
        toast.success(response.data?.message, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("Mood journal error", error);
    }
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/sendmail`,
        {
          customerData,
          fromMail,
        }
      );

      // console.log("Mood journal response", response);
      if (response) {
        navigate("/request-champion");
      }
    } catch (error) {
      console.log("Mood journal error", error);
    }

    window.scrollTo(0, 0);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setUserData(response?.data?.userInfo);
      // console.log("response on create request ==>", response);
      const addressData = JSON.parse(
        response?.data?.userInfo?.user_metadata?.address
      );
      setFormData({
        ...formData,
        streetAddress: addressData?.street_address,
        locality: addressData?.region,
        region: addressData?.region,
        country: addressData?.country,
        postalCode: addressData?.postal_code,
        contactNumber: response?.data?.userInfo?.user_metadata?.phone_number,
        email: response?.data?.userInfo?.email,
      });
      setLoading(false);
    } catch (error) {
      console.log("eeror in dashboard", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function openWidgetArticle(articleId) {
    window.FreshworksWidget("open", "article", { id: articleId });
  }

  const handleCheckboxChange = (item) => {
    // Check if the item is already in the formData.date array
    const isChecked = formData.date.includes(item);
  
    // If it's checked and present in the array, remove it, otherwise add it
    if (isChecked) {
      setFormData({
        ...formData,
        date: formData.date.filter((dateItem) => dateItem !== item),
      });
    } else {
      setFormData({
        ...formData,
        date: [...formData.date, item],
      });
    }
  };
  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="userWrapper">
          <div className="commonWrapper edit-detail">
            <Link to="/request-champion">
              <h3>
                <img className="img-fluid me-2" src={FaAngleLeft} />
                Confirm details
              </h3>
            </Link>
            <hr />
            <p className="mt-8">
              Please review the details below and submit your request to receive
              telephone support from your qualified nurse champion.
            </p>
            <div className="text-start mainData">
              <p>
                <strong>
                  {userData?.given_name} {userData?.family_name}
                </strong>
              </p>

              {formData?.contactNumber}
              <br />
              {edit?.address ? (
                <div className="formContainer mt-3 p-0 ms-0">
                  <Form.Group controlId="formBasicConfirmPassword">
                    <Form.Label>Street Address</Form.Label>

                    <Form.Control
                      className="formMargin"
                      type="text"
                      value={formData?.streetAddress}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          streetAddress: e.target.value,
                        })
                      }
                    />
                    <Form.Label>Locality</Form.Label>

                    <Form.Control
                      className="formMargin"
                      type="text"
                      value={formData?.locality}
                      onChange={(e) =>
                        setFormData({ ...formData, locality: e.target.value })
                      }
                    />
                    <Form.Label>Region</Form.Label>

                    <Form.Control
                      className="formMargin"
                      type="text"
                      value={formData?.region}
                      onChange={(e) =>
                        setFormData({ ...formData, region: e.target.value })
                      }
                    />
                    <Form.Label>Country</Form.Label>

                    <Form.Control
                      className="formMargin"
                      type="text"
                      value={formData?.country}
                      onChange={(e) =>
                        setFormData({ ...formData, country: e.target.value })
                      }
                    />
                    <Form.Label>Postal code</Form.Label>

                    <Form.Control
                      className="formMargin"
                      type="text"
                      value={formData?.postalCode}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          postalCode: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
              ) : (
                // <span>
                //   {formData?.streetAddress} {formData?.locality},{" "}
                //   {formData?.region}, {formData?.country},{" "}
                //   {formData?.postalCode}
                // </span>
                <p>
               <span>
              {formData?.streetAddress && formData.streetAddress}
              {formData?.locality && `, ${formData.locality}`}
              {formData?.region && `, ${formData.region}`}
              {formData?.country && `, ${formData.country}`}
              {formData?.postalCode && `, ${formData.postalCode}`}
              </span>
              </p>
              )}
              <BorderButton
                name={edit.address ? "Update" : "Edit"}
                onClick={() => {
                  if (
                    formData?.streetAddress &&
                    formData?.locality &&
                    formData?.region &&
                    formData?.country &&
                    formData?.postalCode
                  ) {
                    setEdit({ ...edit, address: !edit.address });
                  } else {
                    toast.error("please fill the fields");
                  }
                }}
              />
              <hr />
            </div>
            <div className="text-start mainData">
              <p>
                <strong>You will be contacted at this email:</strong>
              </p>
              {edit?.email ? (
                <div className="formContainer mt-3 p-0 ms-0">
                  <Form.Group
                    className="formMargin"
                    controlId="formBasicConfirmPassword"
                  >
                    <Form.Control
                      type="email"
                      value={formData?.email}
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    />
                  </Form.Group>
                </div>
              ) : (
                <p>{formData?.email}</p>
              )}

              <BorderButton
                name={edit.email ? "Update" : "Edit"}
                onClick={() => {
                  // console.log(
                  //   " /^(([^<>()[]\\.,;:s@",
                  //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  //     formData.email
                  //   ),
                  //   formData.email
                  // );
                  if (formData?.email) {
                    if (
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        formData.email
                      )
                    ) {
                      setEdit({ ...edit, email: !edit.email });
                    } else {
                      toast.error("please fill valid email");
                    }
                  } else {
                    toast.error("please fill the email");
                  }
                }}
              />
              <hr />
            </div>
            <div className="text-start mainData">
              <p className="mb-2">
                <strong>Choose dates you’re available</strong>
              </p>
              <p>
                Your Nurse Champion will schedule your call using these days
              </p>
              {/* <ToggleButtonGroup
                type="checkbox"
                className="multiSelect"
                onChange={(e) => setFormData({ ...formData, date: [...e] })}
              >
                {arr1?.map((item, index) => (
                  <ToggleButton
                    key={index}
                    id={`tbg-btn-${index}`}
                    value={item}
                  >
                    {index == 0 ? "Today" : index == 1 ? "Tomorrow" : item}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup> */}

              <div className="multiSelect">
              {arr1.map((item, index) => (
    <label key={index} className="custom-checkbox">
      <input
        type="checkbox"
        value={item}
        checked={formData.date.includes(item)}
        onChange={(e) => handleCheckboxChange(item)}
      />
      <span>
        {index === 0
          ? "Today"
          : index === 1
          ? "Tomorrow"
          : item}
      </span>
    </label>
  ))}
</div>

              <hr />
            </div>
            <div className="d-flex mb-4">
              <Form.Check
                type="checkbox"
                id="terms"
                onChange={(e) =>
                  setFormData({ ...formData, tnc: e.target.checked })
                }
              />
              <span htmlFor="terms" className="ms-3">
                I agree to CP Nursings <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000370309)}>Terms & Conditions</a>
              </span>
            </div>
            <div className="d-flex mb-5">
              <Form.Check
                type="checkbox"
                id="privacy"
                onChange={(e) =>
                  setFormData({ ...formData, consent: e.target.checked })
                }
              />
              <span htmlFor="privacy" className="ms-3">
                I consent to the data processing described in CP Nursings{" "}
                <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000370309)}>Terms & Conditions</a> and{" "}
                <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000365827)}>Privacy Policy</a>. CP Nursing uses the
                personal data collected here to facilitate service provision in
                accordance with our <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000365827)}>Privacy Policy</a>.
              </span>
            </div>
            <Button
              disabled={
                !formData?.tnc || !formData?.consent || !formData?.date.length
              }
              name="Submit request"
              type="submit"
              onClick={handleSubmit}
            />
          </div>

          <div className="partnerShip">
            <p>In partnership with</p>
            <img className="img-fluid" src={partnerLogo} />
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmChampion;
